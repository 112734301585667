import { NgModule } from '@angular/core';

// Services

@NgModule({
  declarations: [],
  imports: [],
  providers: [],
})
export class AuthModule { }
