import { Application } from "./application";
import { STATUS, APP_SOURCE } from './constants';

export const MOCK_APPS_1 = [
	new Application({
		id: '0',
		company: 'Bob\'s Burgers',
		title: 'Mop',
		date: "2019-01-01",
		status: STATUS.IN_REVIEW.toString(),
		timeline: [
			{ status: STATUS.IN_REVIEW, date: "2019-01-01" },
		],
		source: APP_SOURCE.OTHER.toString(),
		notes: 'will likely be ghosted',
	}),
	new Application({
		id: '1',
		company: 'New Company',
		title: 'Software Developer',
		date: "2020-05-03",
		status: STATUS.IN_REVIEW.toString(),
		source: APP_SOURCE.REFERRAL.toString(),
		notes: '',
	}),
	new Application({
		id: '2',
		company: 'NASA',
		title: 'Engineer',
		date: "2019-01-05",
		status: STATUS.STALE.toString(),
		timeline: [
			{ status: STATUS.IN_REVIEW, date: "2019-01-05" },
			{ status: STATUS.STALE, date: "2020-01-05" },
		],
		source: APP_SOURCE.OTHER.toString(),
		notes: 'something something space simulation something',
	}),
	new Application({
		id: '3',
		company: 'Hooli',
		title: 'Gavin Belson\'s Blood Boy',
		date: "2019-03-12",
		status: STATUS.OFFER.toString(),
		timeline: [
			{ status: STATUS.IN_REVIEW, date: "2019-03-12" },
			{ status: STATUS.ASSESSMENT, date: "2019-03-20" },
			{ status: STATUS.INTERVIEW, date: "2019-04-01" },
			{ status: STATUS.OFFER, date: "2019-04-05" },
		],
		source: APP_SOURCE.JOB_BOARD.toString(),
		notes: 'Welp...',
	}),
	new Application({
		id: '4',
		company: 'Bob\'s Burgers',
		title: 'Mop',
		date: "2019-01-02",
		status: STATUS.REJECTED.toString(),
		timeline: [
			{ status: STATUS.IN_REVIEW, date: "2019-01-02" },
			{ status: STATUS.INTERVIEW, date: "2019-05-20" },
			{ status: STATUS.REJECTED, date: "2019-06-05" },
		],
		source: APP_SOURCE.OTHER.toString(),
		notes: 'probably will be ghosted',
	}),
	new Application({
		id: '5',
		company: 'Pied Piper',
		title: 'Gilfoyle\'s Intern/Little Bitch',
		date: "2020-05-05",
		status: STATUS.INTERVIEW.toString(),
		timeline: [
			{ status: STATUS.IN_REVIEW, date: "2020-05-05" },
			{ status: STATUS.INTERVIEW, date: "2020-05-20" },
		],
		source: APP_SOURCE.JOB_BOARD.toString(),
		notes: 'probably getting ghosted',
	}),

	new Application({
		id: '6',
		company: 'Pied Piper',
		title: 'Gilfoyle\'s Intern/Little Bitch',
		date: "2020-07-20",
		status: STATUS.INTERVIEW.toString(),
		timeline: [
			{ status: STATUS.IN_REVIEW, date: "2020-05-05" },
			{ status: STATUS.INTERVIEW, date: "2020-05-20" },
		],
		source: APP_SOURCE.JOB_BOARD.toString(),
		notes: 'probably getting ghosted',
	}),
	new Application({
		id: '7',
		company: 'Pied Piper',
		title: 'Gilfoyle\'s Intern/Little Bitch',
		date: "2020-07-19",
		status: STATUS.INTERVIEW.toString(),
		timeline: [
			{ status: STATUS.IN_REVIEW, date: "2020-05-05" },
			{ status: STATUS.INTERVIEW, date: "2020-05-20" },
		],
		source: APP_SOURCE.JOB_BOARD.toString(),
		notes: 'probably getting ghosted',
	}),
	new Application({
		id: '8',
		company: 'Pied Piper',
		title: 'Gilfoyle\'s Intern/Little Bitch',
		date: "2020-07-19",
		status: STATUS.INTERVIEW.toString(),
		timeline: [
			{ status: STATUS.IN_REVIEW, date: "2020-05-05" },
			{ status: STATUS.INTERVIEW, date: "2020-05-20" },
		],
		source: APP_SOURCE.JOB_BOARD.toString(),
		notes: 'probably getting ghosted',
	}),
	new Application({
		id: '9',
		company: 'Pied Piper',
		title: 'Gilfoyle\'s Intern/Little Bitch',
		date: "2020-07-19",
		status: STATUS.INTERVIEW.toString(),
		timeline: [
			{ status: STATUS.IN_REVIEW, date: "2020-05-05" },
			{ status: STATUS.INTERVIEW, date: "2020-05-20" },
		],
		source: APP_SOURCE.JOB_BOARD.toString(),
		notes: 'probably getting ghosted',
	}),
	new Application({
		id: '10',
		company: 'Pied Piper',
		title: 'Gilfoyle\'s Intern/Little Bitch',
		date: "2020-07-18",
		status: STATUS.INTERVIEW.toString(),
		timeline: [
			{ status: STATUS.IN_REVIEW, date: "2020-05-05" },
			{ status: STATUS.INTERVIEW, date: "2020-05-20" },
		],
		source: APP_SOURCE.JOB_BOARD.toString(),
		notes: 'probably getting ghosted',
	}),
	new Application({
		id: '11',
		company: 'Pied Piper',
		title: 'Gilfoyle\'s Intern/Little Bitch',
		date: "2020-07-18",
		status: STATUS.INTERVIEW.toString(),
		timeline: [
			{ status: STATUS.IN_REVIEW, date: "2020-05-05" },
			{ status: STATUS.INTERVIEW, date: "2020-05-20" },
		],
		source: APP_SOURCE.JOB_BOARD.toString(),
		notes: 'probably getting ghosted',
	}),
	new Application({
		id: '12',
		company: 'Pied Piper',
		title: 'Gilfoyle\'s Intern/Little Bitch',
		date: "2020-07-18",
		status: STATUS.INTERVIEW.toString(),
		timeline: [
			{ status: STATUS.IN_REVIEW, date: "2020-05-05" },
			{ status: STATUS.INTERVIEW, date: "2020-05-20" },
		],
		source: APP_SOURCE.JOB_BOARD.toString(),
		notes: 'probably getting ghosted',
	}),
	new Application({
		id: '13',
		company: 'Pied Piper',
		title: 'Gilfoyle\'s Intern/Little Bitch',
		date: "2020-07-17",
		status: STATUS.INTERVIEW.toString(),
		timeline: [
			{ status: STATUS.IN_REVIEW, date: "2020-05-05" },
			{ status: STATUS.INTERVIEW, date: "2020-05-20" },
		],
		source: APP_SOURCE.JOB_BOARD.toString(),
		notes: 'probably getting ghosted',
	}),
	new Application({
		id: '14',
		company: 'Pied Piper',
		title: 'Gilfoyle\'s Intern/Little Bitch',
		date: "2020-07-16",
		status: STATUS.INTERVIEW.toString(),
		timeline: [
			{ status: STATUS.IN_REVIEW, date: "2020-05-05" },
			{ status: STATUS.INTERVIEW, date: "2020-05-20" },
		],
		source: APP_SOURCE.JOB_BOARD.toString(),
		notes: 'probably getting ghosted',
	}),
	new Application({
		id: '15',
		company: 'Pied Piper',
		title: 'Gilfoyle\'s Intern/Little Bitch',
		date: "2020-07-16",
		status: STATUS.INTERVIEW.toString(),
		timeline: [
			{ status: STATUS.IN_REVIEW, date: "2020-05-05" },
			{ status: STATUS.INTERVIEW, date: "2020-05-20" },
		],
		source: APP_SOURCE.JOB_BOARD.toString(),
		notes: 'probably getting ghosted',
	}),
	new Application({
		id: '16',
		company: 'Pied Piper',
		title: 'Gilfoyle\'s Intern/Little Bitch',
		date: "2020-07-16",
		status: STATUS.INTERVIEW.toString(),
		timeline: [
			{ status: STATUS.IN_REVIEW, date: "2020-05-05" },
			{ status: STATUS.INTERVIEW, date: "2020-05-20" },
		],
		source: APP_SOURCE.JOB_BOARD.toString(),
		notes: 'probably getting ghosted',
	}),
	new Application({
		id: '17',
		company: 'Pied Piper',
		title: 'Gilfoyle\'s Intern/Little Bitch',
		date: "2020-07-16",
		status: STATUS.INTERVIEW.toString(),
		timeline: [
			{ status: STATUS.IN_REVIEW, date: "2020-05-05" },
			{ status: STATUS.INTERVIEW, date: "2020-05-20" },
		],
		source: APP_SOURCE.JOB_BOARD.toString(),
		notes: 'probably getting ghosted',
	}),
	new Application({
		id: '18',
		company: 'Pied Piper',
		title: 'Gilfoyle\'s Intern/Little Bitch',
		date: "2020-07-15",
		status: STATUS.INTERVIEW.toString(),
		timeline: [
			{ status: STATUS.IN_REVIEW, date: "2020-05-05" },
			{ status: STATUS.INTERVIEW, date: "2020-05-20" },
		],
		source: APP_SOURCE.JOB_BOARD.toString(),
		notes: 'probably getting ghosted',
	}),
	new Application({
		id: '19',
		company: 'Pied Piper',
		title: 'Gilfoyle\'s Intern/Little Bitch',
		date: "2020-07-14",
		status: STATUS.INTERVIEW.toString(),
		timeline: [
			{ status: STATUS.IN_REVIEW, date: "2020-05-05" },
			{ status: STATUS.INTERVIEW, date: "2020-05-20" },
		],
		source: APP_SOURCE.JOB_BOARD.toString(),
		notes: 'probably getting ghosted',
	}),
	new Application({
		id: '20',
		company: 'Pied Piper',
		title: 'Gilfoyle\'s Intern/Little Bitch',
		date: "2020-07-26",
		status: STATUS.ASSESSMENT.toString(),
		timeline: [
			{ status: STATUS.IN_REVIEW, date: "2020-05-05" },
			{ status: STATUS.INTERVIEW, date: "2020-05-20" },
			{ status: STATUS.ASSESSMENT, date: "2020-08-02" },
		],
		source: APP_SOURCE.JOB_BOARD.toString(),
		notes: 'probably getting ghosted',
	}),
	new Application({
		id: '21',
		company: 'Pied Piper',
		title: 'Gilfoyle\'s Intern/Little Bitch',
		date: "2020-07-26",
		status: STATUS.INTERVIEW.toString(),
		timeline: [
			{ status: STATUS.IN_REVIEW, date: "2020-05-05" },
			{ status: STATUS.INTERVIEW, date: "2020-05-20" },
		],
		source: APP_SOURCE.JOB_BOARD.toString(),
		notes: 'probably getting ghosted',
	}),
	new Application({
		id: '22',
		company: 'Pied Piper',
		title: 'Gilfoyle\'s Intern/Little Bitch',
		date: "2020-07-14",
		status: STATUS.INTERVIEW.toString(),
		timeline: [
			{ status: STATUS.IN_REVIEW, date: "2020-05-05" },
			{ status: STATUS.INTERVIEW, date: "2020-05-20" },
		],
		source: APP_SOURCE.JOB_BOARD.toString(),
		notes: 'probably getting ghosted',
	}),
	new Application({
		id: '23',
		company: 'Pied Piper',
		title: 'Gilfoyle\'s Intern/Little Bitch',
		date: "2020-07-27",
		status: STATUS.INTERVIEW.toString(),
		timeline: [
			{ status: STATUS.IN_REVIEW, date: "2020-05-05" },
			{ status: STATUS.INTERVIEW, date: "2020-05-20" },
		],
		source: APP_SOURCE.JOB_BOARD.toString(),
		notes: 'probably getting ghosted',
	}),
	new Application({
		id: '24',
		company: 'Pied Piper',
		title: 'Gilfoyle\'s Intern/Little Bitch',
		date: "2020-07-24",
		status: STATUS.INTERVIEW.toString(),
		timeline: [
			{ status: STATUS.IN_REVIEW, date: "2020-05-05" },
			{ status: STATUS.INTERVIEW, date: "2020-05-20" },
		],
		source: APP_SOURCE.JOB_BOARD.toString(),
		notes: 'probably getting ghosted',
	}),
	new Application({
		id: '25',
		company: 'Pied Piper',
		title: 'Gilfoyle\'s Intern/Little Bitch',
		date: "2020-07-24",
		status: STATUS.INTERVIEW.toString(),
		timeline: [
			{ status: STATUS.IN_REVIEW, date: "2020-05-05" },
			{ status: STATUS.INTERVIEW, date: "2020-05-20" },
		],
		source: APP_SOURCE.JOB_BOARD.toString(),
		notes: 'probably getting ghosted',
	}),
	new Application({
		id: '26',
		company: 'Pied Piper',
		title: 'Gilfoyle\'s Intern/Little Bitch',
		date: "2020-07-14",
		status: STATUS.INTERVIEW.toString(),
		timeline: [
			{ status: STATUS.IN_REVIEW, date: "2020-05-05" },
			{ status: STATUS.INTERVIEW, date: "2020-05-20" },
		],
		source: APP_SOURCE.JOB_BOARD.toString(),
		notes: 'probably getting ghosted',
	}),
	new Application({
		id: '27',
		company: 'Pied Piper',
		title: 'Gilfoyle\'s Intern/Little Bitch',
		date: "2020-07-14",
		status: STATUS.ASSESSMENT.toString(),
		timeline: [
			{ status: STATUS.IN_REVIEW, date: "2020-05-05" },
			{ status: STATUS.INTERVIEW, date: "2020-05-20" },
			{ status: STATUS.ASSESSMENT, date: "2020-08-02" },
		],
		source: APP_SOURCE.JOB_BOARD.toString(),
		notes: 'probably getting ghosted',
	}),
	new Application({
		id: '28',
		company: 'Pied Piper',
		title: 'Gilfoyle\'s Intern/Little Bitch',
		date: "2020-07-25",
		status: STATUS.INTERVIEW.toString(),
		timeline: [
			{ status: STATUS.IN_REVIEW, date: "2020-05-05" },
			{ status: STATUS.INTERVIEW, date: "2020-05-20" },
		],
		source: APP_SOURCE.JOB_BOARD.toString(),
		notes: 'probably getting ghosted',
	}),
	new Application({
		id: '29',
		company: 'Pied Piper',
		title: 'Gilfoyle\'s Intern/Little Bitch',
		date: "2020-07-25",
		status: STATUS.INTERVIEW.toString(),
		timeline: [
			{ status: STATUS.IN_REVIEW, date: "2020-05-05" },
			{ status: STATUS.INTERVIEW, date: "2020-05-20" },
		],
		source: APP_SOURCE.JOB_BOARD.toString(),
		notes: 'probably getting ghosted',
	}),
	new Application({
		id: '30',
		company: 'Pied Piper',
		title: 'Gilfoyle\'s Intern/Little Bitch',
		date: "2020-07-25",
		status: STATUS.INTERVIEW.toString(),
		timeline: [
			{ status: STATUS.IN_REVIEW, date: "2020-05-05" },
			{ status: STATUS.INTERVIEW, date: "2020-05-20" },
		],
		source: APP_SOURCE.JOB_BOARD.toString(),
		notes: 'probably getting ghosted',
	}),
	new Application({
		id: '31',
		company: 'Pied Piper',
		title: 'Gilfoyle\'s Intern/Little Bitch',
		date: "2020-07-14",
		status: STATUS.INTERVIEW.toString(),
		timeline: [
			{ status: STATUS.IN_REVIEW, date: "2020-05-05" },
			{ status: STATUS.INTERVIEW, date: "2020-05-20" },
		],
		source: APP_SOURCE.JOB_BOARD.toString(),
		notes: 'probably getting ghosted',
	}),
	new Application({
		id: '32',
		company: 'Pied Piper',
		title: 'Gilfoyle\'s Intern/Little Bitch',
		date: "2020-07-14",
		status: STATUS.INTERVIEW.toString(),
		timeline: [
			{ status: STATUS.IN_REVIEW, date: "2020-05-05" },
			{ status: STATUS.INTERVIEW, date: "2020-05-20" },
		],
		source: APP_SOURCE.JOB_BOARD.toString(),
		notes: 'probably getting ghosted',
	}),
	new Application({
		id: '33',
		company: 'Pied Piper',
		title: 'Gilfoyle\'s Intern/Little Bitch',
		date: "2020-07-14",
		status: STATUS.INTERVIEW.toString(),
		timeline: [
			{ status: STATUS.IN_REVIEW, date: "2020-05-05" },
			{ status: STATUS.INTERVIEW, date: "2020-05-20" },
		],
		source: APP_SOURCE.JOB_BOARD.toString(),
		notes: 'probably getting ghosted',
	}),
	new Application({
		id: '34',
		company: 'Pied Piper',
		title: 'Gilfoyle\'s Intern/Little Bitch',
		date: "2020-07-14",
		status: STATUS.INTERVIEW.toString(),
		timeline: [
			{ status: STATUS.IN_REVIEW, date: "2020-05-05" },
			{ status: STATUS.INTERVIEW, date: "2020-05-20" },
		],
		source: APP_SOURCE.JOB_BOARD.toString(),
		notes: 'probably getting ghosted',
	}),
	new Application({
		id: '35',
		company: 'Pied Piper',
		title: 'Gilfoyle\'s Intern/Little Bitch',
		date: "2020-07-14",
		status: STATUS.INTERVIEW.toString(),
		timeline: [
			{ status: STATUS.IN_REVIEW, date: "2020-05-05" },
			{ status: STATUS.INTERVIEW, date: "2020-05-20" },
		],
		source: APP_SOURCE.JOB_BOARD.toString(),
		notes: 'probably getting ghosted',
	}),
];

export const MOCK_APPS_2 = [
	new Application({
		id: '0',
		company: 'Bob\'s Burgers',
		title: 'Cook',
		date: "2020-01-01",
		status: STATUS.IN_REVIEW.toString(),
		source: APP_SOURCE.OTHER.toString(),
		notes: 'will likely be ghosted',
	}),
	new Application({
		id: '1',
		company: 'New Company',
		title: 'Software Developer',
		date: "2020-03-12",
		status: STATUS.IN_REVIEW.toString(),
		source: APP_SOURCE.REFERRAL.toString(),
		notes: '',
	}),
	new Application({
		id: '2',
		company: 'Bob\'s Burgers',
		title: 'Cook',
		date: "2020-01-01",
		status: STATUS.ASSESSMENT.toString(),
		timeline: [
			{ status: STATUS.IN_REVIEW, date: "2020-01-01" },
			{ status: STATUS.ASSESSMENT, date: "2020-04-01" },
		],
		source: APP_SOURCE.OTHER.toString(),
		notes: 'probably will be ghosted',
	}),
	new Application({
		id: '3',
		company: 'Pied Piper',
		title: 'Gilfoyle\'s Intern/Little Bitch',
		date: "2020-05-05",
		status: STATUS.INTERVIEW.toString(),
		timeline: [
			{ status: STATUS.IN_REVIEW, date: "2020-05-05" },
			{ status: STATUS.ASSESSMENT, date: "2020-06-01" },
			{ status: STATUS.INTERVIEW, date: "2020-06-20" },
		],
		source: APP_SOURCE.JOB_BOARD.toString(),
		notes: 'probably will be ghosted',
	}),
];

export const MOCK_APPS_3 = [
	new Application({
		id: '0',
		company: 'New Company',
		title: 'Software Developer',
		date: "2020-03-05",
		status: STATUS.ASSESSMENT.toString(),
		timeline: [
			{ status: STATUS.IN_REVIEW, date: "2020-03-05" },
			{ status: STATUS.ASSESSMENT, date: "2020-04-01" },
		],
		source: APP_SOURCE.REFERRAL.toString(),
		notes: '',
	}),
	new Application({
		id: '1',
		company: 'NASA',
		title: 'Engineer',
		date: "2020-01-05",
		status: STATUS.STALE.toString(),
		timeline: [
			{ status: STATUS.IN_REVIEW, date: new Date("2020-01-05") },
			{ status: STATUS.ASSESSMENT, date: new Date("2020-03-01") },
			{ status: STATUS.INTERVIEW, date: new Date("2020-03-12") },
			{ status: STATUS.OFFER, date: new Date("2020-04-05") },
			{ status: STATUS.STALE, date: new Date("2020-04-06") },
		],
		source: APP_SOURCE.OTHER.toString(),
		notes: 'something something space simulation something',
	}),
	new Application({
		id: '2',
		company: 'Hooli',
		title: 'Gavin Belson\'s Blood Boy',
		date: "2019-03-12",
		status: STATUS.OFFER.toString(),
		timeline: [
			{ status: STATUS.IN_REVIEW, date: "2019-03-12" },
			{ status: STATUS.ASSESSMENT, date: "2019-03-20" },
			{ status: STATUS.INTERVIEW, date: "2019-04-01" },
			{ status: STATUS.OFFER, date: "2019-04-05" },
		],
		source: APP_SOURCE.JOB_BOARD.toString(),
		notes: 'Welp...',
	}),
	new Application({
		id: '3',
		company: 'Bob\'s Burgers',
		title: 'Cook',
		date: "2019-01-01",
		status: STATUS.IN_REVIEW.toString(),
		source: APP_SOURCE.OTHER.toString(),
		notes: 'probably will be ghosted',
	}),
];