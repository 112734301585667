<form>
  <div class="{{center ? 'input-container center' : 'input-container'}}" style="max-width: {{width}}px;">
    <p *ngIf="type == 'email' && textfield.invalid && displayErrorMessage; else genericError" class="warning-text">{{label}}
      is not valid</p>
    <ng-template #genericError>
      <p *ngIf="displayErrorMessage && textfield.dirty && value == ''" class="warning-text">{{label}} cannot be empty</p>
    </ng-template>
    <div class="{{
      showUpdatedBorder
      ? (control.valid ? 'updated' : 'invalid')
      : (displayFeedback && !inFocus && control.dirty
        ? (control.valid ? '' : 'invalid')
        : '')
    }}">
      <input
        [(ngModel)]="value"
        [formControl]="control"
        (focus)="onFocus()" (focusout)="onFocusOut()" (input)="onInput()"
        style="font-size: {{fontSize}}pt; color: {{fontColor}};"
        name="textfield"
        type="{{currType}}"
        placeholder="{{
          displayPlaceholder
          ? (placeholder ? placeholder : label)
          : ''}}"
        maxLength="{{maxLength}}"
        style="padding: {{ verticalPadding }} {{ horizontalPadding }};"
        #input #textfield />
      <mat-icon *ngIf="type == 'password'" (click)="togglePasswordVisibility()">{{visibilityIconName}}</mat-icon>
      <mat-icon *ngIf="suffixIcon && type != 'password'">{{suffixIcon}}</mat-icon>
    </div>
    <label *ngIf="displayLabel" for="textfield">{{fieldLabel}}</label>
  </div>
  <mat-icon class="feedback" *ngIf="displayFeedback && !inFocus && control.dirty && !control.valid" title="value is either empty or invalid">clear</mat-icon>
</form>
